@import url(https://fonts.googleapis.com/css?family=PT+Mono);
@import url(https://fonts.googleapis.com/css?family=Kurale);

body {
    margin: auto;
    overflow: auto;
    font-family: "Kurale", serif !important;
    color: white !important;
    height: 100vh;
}

@keyframes gradient {
    0% {
        background-position: 100% 100%;
    }
    50% {
        background-position: 0% 0%;
    }
    100% {
        background-position: 100% 100%;
    }
}

#navbar {
    animation: gradient 15s ease infinite;
    background-size: 200% 200%;
    background-attachment: fixed;
}

.header {
    background-color: #393e46;
    font-weight: bolder;
    filter: sepia(0.8);
}

.content,
.nav-button {
    color: white;
    letter-spacing: 0.05rem;
    transition: 0.3s ease-in-out;
    transform-origin: bottom center;
}

.nav-button:hover {
    letter-spacing: 0.1rem;
}

.main {
    width: 85%;
    padding: 0.5rem;
    margin-top: 5rem;
    border-radius: 1rem;
    background-color: #0000007f;
    margin-bottom: 15rem;
}

.title {
    height: 5rem;
}

.intro-text {
    font-weight: bold;
    font-family: "PT mono";
    letter-spacing: 1.5px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: transparent;
}

.intro-txt5,
.intro-txt8,
.intro-txt9,
.intro-txt11,
.intro-txt19,
.intro-txt14 {
    border-right: solid 3px transparent;
    white-space: nowrap;
    overflow: hidden;
    font-size: 28px;
    margin: 0 auto;
    color: white;
}

.intro-txt5 {
    animation: animate-text 0.5s steps(5, end) 1s 1 normal both,
        animated-cursor 600ms steps(22, end) 3;
}

.intro-txt9 {
    animation: animate-text 1s steps(9, end) 1s 1 normal both,
        animated-cursor 600ms steps(22, end) 5;
}
.intro-txt8 {
    animation: animate-text 1s steps(8, end) 1s 1 normal both,
        animated-cursor 600ms steps(22, end) 4;
}

.intro-txt11 {
    animation: animate-text 1s steps(11, end) 1s 1 normal both,
        animated-cursor 600ms steps(22, end) 5;
}

.intro-txt19 {
    animation: animate-text 1.5s steps(19, end) 1s 1 normal both,
        animated-cursor 600ms steps(22, end) 10;
}

.intro-txt14 {
    animation: animate-text 1.5s steps(14, end) 1s 1 normal both,
        animated-cursor 600ms steps(22, end) 10;
}

.status-txt {
    animation-delay: 3s, 3s;
}

@keyframes animate-text {
    from {
        width: 0;
    }
    to {
        width: 100%;
    }
}

@keyframes animated-cursor {
    from {
        border-right-color: rgba(255, 255, 255, 0.75);
    }
    to {
        border-right-color: transparent;
    }
}
