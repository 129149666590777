@import "https://fonts.googleapis.com/css?family=PT+Mono";
@import "https://fonts.googleapis.com/css?family=Kurale";
body {
  height: 100vh;
  margin: auto;
  overflow: auto;
  color: #fff !important;
  font-family: Kurale, serif !important;
}

@keyframes gradient {
  0% {
    background-position: 100% 100%;
  }

  50% {
    background-position: 0 0;
  }

  100% {
    background-position: 100% 100%;
  }
}

#navbar {
  background-size: 200% 200%;
  background-attachment: fixed;
  animation: 15s infinite gradient;
}

.header {
  filter: sepia(.8);
  background-color: #393e46;
  font-weight: bolder;
}

.content, .nav-button {
  color: #fff;
  letter-spacing: .05rem;
  transform-origin: bottom;
  transition: all .3s ease-in-out;
}

.nav-button:hover {
  letter-spacing: .1rem;
}

.main {
  width: 85%;
  background-color: #0000007f;
  border-radius: 1rem;
  margin-top: 5rem;
  margin-bottom: 15rem;
  padding: .5rem;
}

.title {
  height: 5rem;
}

.intro-text {
  letter-spacing: 1.5px;
  color: #0000;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-family: PT mono;
  font-weight: bold;
  display: flex;
}

.intro-txt5, .intro-txt8, .intro-txt9, .intro-txt11, .intro-txt19, .intro-txt14 {
  white-space: nowrap;
  color: #fff;
  border-right: 3px solid #0000;
  margin: 0 auto;
  font-size: 28px;
  overflow: hidden;
}

.intro-txt5 {
  animation: .5s steps(5, end) 1s both animate-text, .6s steps(22, end) 3 animated-cursor;
}

.intro-txt9 {
  animation: 1s steps(9, end) 1s both animate-text, .6s steps(22, end) 5 animated-cursor;
}

.intro-txt8 {
  animation: 1s steps(8, end) 1s both animate-text, .6s steps(22, end) 4 animated-cursor;
}

.intro-txt11 {
  animation: 1s steps(11, end) 1s both animate-text, .6s steps(22, end) 5 animated-cursor;
}

.intro-txt19 {
  animation: 1.5s steps(19, end) 1s both animate-text, .6s steps(22, end) 10 animated-cursor;
}

.intro-txt14 {
  animation: 1.5s steps(14, end) 1s both animate-text, .6s steps(22, end) 10 animated-cursor;
}

.status-txt {
  animation-delay: 3s, 3s;
}

@keyframes animate-text {
  from {
    width: 0;
  }

  to {
    width: 100%;
  }
}

@keyframes animated-cursor {
  from {
    border-right-color: #ffffffbf;
  }

  to {
    border-right-color: #0000;
  }
}

/*# sourceMappingURL=style.css.map */
